//main.js

$("#fitText").fitText(0.85);

// $("#mediaBox").fitVids();

$(".mediaToggle").click(function(e) {
	e.preventDefault();

	if (!$(this).hasClass("selected")) {

		$(".mediaToggle").each(function() {

			$(this).removeClass("selected");
		});

		$(this).addClass("selected");

		var $embedTarget = $("#mediaBox");
		var $captionElement = $("#caption");

		var embedSource = $(this).data("embed");
		var embedCaption = $(this).data("caption");

		$captionElement.html(embedCaption);
		$embedTarget.html(embedSource);

	}
});


$('#videoToggle').magnificPopup({
	disableOn: 700,
	type: 'iframe',
	mainClass: 'mfp-fade',
	removalDelay: 160,
	preloader: false,

	fixedContentPos: false
});
